<template>
	<div class="home" id="home">
		<div class="top-banner">
			<el-carousel trigger="click" :height="isMobile ? '265px' : '580px'">
				<el-carousel-item :style="{ backgroundImage: `url('${webImg + item.mobileBannerImg}')` }" v-for="(item, index) in bannerList" :key="index"></el-carousel-item>
			</el-carousel>
		</div>
		<div class="section-1">
			<div class="wrap">
				<div class="item animate_box-shadow-gray" >
					<div class="pic-box"><h-img class="pic" :src="require('@/assets/071.png')"></h-img></div>
					<div class="right-block">
						<div class="title">银联刷卡</div>
						<div class="txt-1">银联刷卡、云闪付、</div>
						<div class="txt-2">微信、支付宝全渠道受理</div>
					</div>
				</div>
				<div class="item animate_box-shadow-gray" >
					<div class="pic-box"><h-img class="pic" :src="require('@/assets/072.png')"></h-img></div>
					<div class="right-block">
						<div class="title">公众号、小程序</div>
						<div class="txt-1">满足商户自有载体</div>
						<div class="txt-2">线上支付需求</div>
					</div>
				</div>
				<div class="item animate_box-shadow-gray" >
					<div class="pic-box"><h-img class="pic" :src="require('@/assets/073.png')"></h-img></div>
					<div class="right-block">
						<div class="title">H5</div>
						<div class="txt-1">基于H5、链接等作为商户</div>
						<div class="txt-2">与用户载体完成支付</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flow">
			<div class="wrap">
				<div class="title">支付流程</div>
				<h-img class="pic-070" :src="require('@/assets/070.png')"></h-img>
			</div>
		</div>
		<div class="section-2">
			<div class="wrap">
				<div class="title">选择您所需的服务</div>
				<div class="description">银联产品在手，精彩一路随行</div>
				<div class="nav-list">
					<div
						class="item"
						:class="[`item-${index + 1}`, index == tab_active ? 'active' : '']"
						v-for="(item, index) in tab_List"
						:key="index"
						@click="tab_active = index"
					>
						<div class="icon"></div>
						<div class="txt">{{ item }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section-3" :style="{backgroundImage: tab_active == 0 ? `url('${require('@/assets/041.jpg')}')` : `url('${require('@/assets/063.jpg')}')`}">
			<div class="wrap" v-show="tab_active == 0">
				<div class="title-box">
					<div class="title">个人业务</div>
					<div class="description">为个人用户带来最便捷的支付和优惠权益</div>
				</div>
				<div class="nav-box">
					<div class="left-block animate_box-shadow-gray" >
						<div class="title">生活便民服务</div>
						<div class="txt-1">便民惠民</div>
						<div class="txt-2">支付为民</div>
						<h-img class="pic" :src="require('@/assets/042.jpg')"></h-img>
					</div>
					<div class="right-block">
						<div class="item animate_box-shadow-gray" >
							<h-img class="pic" :src="require('@/assets/043.png')"></h-img>
							<div class="title">银联二维码</div>
							<div class="txt-1">畅享移动支付的便捷</div>
							<div class="txt-2">更享有银行等级的安全保障</div>
						</div>
						<div class="item animate_box-shadow-gray" >
							<h-img class="pic" :src="require('@/assets/044.png')"></h-img>
							<div class="title">绿色低碳卡</div>
							<div class="txt-1">低碳小积分</div>
							<div class="txt-2">绿色大未来</div>
						</div>
						<div class="item animate_box-shadow-gray" >
							<h-img class="pic" :src="require('@/assets/045.png')"></h-img>
							<div class="title">支付安全</div>
							<div class="txt-1">中国银联</div>
							<div class="txt-2">为您的支付体验保驾护航</div>
						</div>
						<div class="item animate_box-shadow-gray" >
							<h-img class="pic" :src="require('@/assets/046.png')"></h-img>
							<div class="title">营销优惠活动</div>
							<div class="txt-1">竭诚为您精心挑选</div>
							<div class="txt-2">全国逾3000家品牌精选优惠</div>
						</div>
					</div>
				</div>
			</div>
			<div class="wrap" v-show="tab_active == 1">
				<div class="title-box">
					<div class="title">商户业务</div>
					<div class="description">为商户提供最佳服务与专业解决方案</div>
				</div>
				<div class="nav-box">
					<div class="left-block animate_box-shadow-gray" >
						<div class="title">场景开放服务</div>
						<div class="txt-1">深耕多个行业</div>
						<div class="txt-2">提供不同场景下的解决方案与服务</div>
						<h-img class="pic" :src="require('@/assets/064.png')"></h-img>
					</div>
					<div class="right-block">
						<div class="item animate_box-shadow-gray" >
							<h-img class="pic" :src="require('@/assets/065.png')"></h-img>
							<div class="title">银联线下收银台</div>
							<div class="txt-1">打造统一受理标识</div>
							<div class="txt-2">提升收银与支付体验</div>
						</div>
						<div class="item animate_box-shadow-gray" >
							<h-img class="pic" :src="require('@/assets/066.png')"></h-img>
							<div class="title">商家服务中心</div>
							<div class="txt-1">全行业服务深度定制</div>
							<div class="txt-2">探索无限商业价值</div>
						</div>
						<div class="item animate_box-shadow-gray" >
							<h-img class="pic" :src="require('@/assets/067.png')"></h-img>
							<div class="title">商户智能决策平台</div>
							<div class="txt-1">提供与多行业应用场景</div>
							<div class="txt-2">高度结合的精准数据增值服务</div>
						</div>
						<div class="item animate_box-shadow-gray" >
							<h-img class="pic" :src="require('@/assets/068.png')"></h-img>
							<div class="title">最佳实践分享</div>
							<div class="txt-1">专业化的解决方案</div>
							<div class="txt-2">助力业务提效增速</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section-4">
			<div class="wrap">
				<div class="header-block">
					<div class="heading">新闻中心</div>
					<div class="nav-box">
						<div
							class="item"
							:class="item.name == menuItem_xinWenZhongXin_active_name ? 'active' : ''"
							v-for="(item, index) in menuItem_xinWenZhongXin.children"
							:key="index"
							@click="menuItem_xinWenZhongXin_item_click(item, index)"
						>
							{{ item.name }}
						</div>
					</div>
					<div class="more-box" @click="xinWenZhongXin_more_click">
						<div class="txt">查看更多</div>
						<h-img class="icon" :src="require('@/assets/053.png')"></h-img>
					</div>
				</div>
				<div class="body-block">
					<div class="left-block">
						<el-carousel trigger="click" :height="isMobile ? '210px' : '332px'">
							<el-carousel-item v-if="index < 3" v-for="(item, index) in xinWenZhongXin" :key="index">
								<div class="el-carousel-item-content" @click="$router.push({name: 'newsDetail', params: {id: item.id}})">
									<h-img class="pic" :src="item.contentImg"></h-img>
									<div class="date">
										{{
											getDate(item.createTime)
												.split('-')
												.join('.')
										}}
									</div>
									<div class="title">{{ item.contentTitle }}</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="right-block">
						<div class="item" v-if="index < 6 && index > 2" v-for="(item, index) in xinWenZhongXin" :key="index" @click="$router.push({name: 'newsDetail', params: {id: item.id}})">
							<div class="date">
								{{
									getDate(item.createTime)
										.split('-')
										.join('.')
								}}
							</div>
							<div class="title">{{ item.contentTitle }}</div>
							<div class="description">{{ item.contentDescription }}</div>
						</div>
					</div>
				</div>
				<div class="footer-block" v-if="isMobile" @click="xinWenZhongXin_more_click">查看更多</div>
			</div>
		</div>
		<div class="section-5">
			<div class="wrap">
				<div class="heading"></div>
				<div class="body-block">
					<h-img></h-img>
					<h-img></h-img>
					.
				</div>
			</div>
		</div>
		<div class="section-6"></div>
	</div>
</template>

<script>
import { BannerList } from '@/apis/lunBoTu.js';
import { ContentPage } from '@/apis/wenZhangLanMu.js';
export default {
	data() {
		return {
			bannerList: [],
			tab_List: ['个人业务', '商户业务'],
			tab_active: 0,
			menuItem_xinWenZhongXin: this.$store.getters.getMenuItem('新闻中心'),
			menuItem_xinWenZhongXin_active_name: '',

			// chanPinFuWu: [],
			// woMenDeYouShi: [],
			xinWenZhongXin: []
			// menuItem_xinWenZhongXin: {},
			// menuItem_xinWenZhongXin_active_menuId: '',
			// rongYuZhanShi: []
		};
	},
	created() {
		this.getBannerList();
		this.getMenuListForXinWenZhongXin();
		// this.getContentListForRongYuZhanShi();
	},
	methods: {
		getBannerList() {
			BannerList().then(res => {
				this.bannerList = res.data;
			});
		},
		getMenuListForXinWenZhongXin() {
			this.menuItem_xinWenZhongXin_active_name = this.menuItem_xinWenZhongXin.children[0].name;
			this.getContentPageForXinWenZhongXin();
		},
		getContentPageForXinWenZhongXin() {
			let menuId = -1;
			if (this.menuItem_xinWenZhongXin_active_name == '签约动态') {
				menuId = 2058;
			} else if (this.menuItem_xinWenZhongXin_active_name == '公司动态') {
				menuId = 2059;
			} else if (this.menuItem_xinWenZhongXin_active_name == '银联新闻') {
				menuId = 2085;
			}
			ContentPage(menuId, '', 1, 7).then(res => {
				this.xinWenZhongXin = res.data;
			});
		},
		menuItem_xinWenZhongXin_item_click(item, index) {
			if (this.menuItem_xinWenZhongXin_active_name == item.name) {
				return;
			} else {
				this.menuItem_xinWenZhongXin_active_name = item.name;
				this.getContentPageForXinWenZhongXin();
			}
		},
		xinWenZhongXin_more_click() {
			this.$store.commit('changeErJiMenuPath', this.menuItem_xinWenZhongXin_active_name)
			this.$router.push({ name: 'newsCenter'});
		},
	}
};
</script>

<style lang="scss" scoped>
@import 'home.scss';
@import 'homeMedia.scss';
</style>
